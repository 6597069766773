import styled from '@emotion/styled';
import { Checkbox, SearchInput, colors, sizes } from '@smartsheet/lodestar-core';
import { TrashIcon } from '@smartsheet/lodestar-icons';
import * as React from 'react';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import { WithDataClientProps } from '../../components/hoc/WithDataClient';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';
import AdminControlsActionButton from './AdminControlsActionButton';
import { getCheckedState } from './AdminControlsUtils';

interface Props extends WithDataClientProps {
    selectedViewsSize: number;
    viewsTotal: number;
    searchValue: string;
    isBulkDeleteDisabled: boolean;
    onSetSearchValue: (search: string) => void;
    onSelectAllViews: () => void;
    onBulkDelete: () => void;
}

const AdminControlsActionsBar: React.FC<Props> = ({
    searchValue,
    selectedViewsSize,
    viewsTotal,
    isBulkDeleteDisabled,
    onBulkDelete,
    onSelectAllViews,
    onSetSearchValue,
}) => {
    const languageElements = useLanguageElements();

    return (
        <Container>
            <ButtonsBlock>
                <CheckboxStyled
                    label={languageElements.ADMIN_CONTROLS_AMOUNT_OF_SELECTED_ITEMS(selectedViewsSize, viewsTotal)}
                    checkedState={getCheckedState(selectedViewsSize, viewsTotal)}
                    onClick={onSelectAllViews}
                />
                <Divider />
                <AdminControlsActionButton
                    dataTestId={AutomationIds.ADMIN_CONTROLS_BULK_DELETE_BUTTON}
                    icon={<TrashIcon size="medium" />}
                    flyoutText={languageElements.ADMIN_CONTROLS_BULK_DELETE_BUTTON}
                    onClick={onBulkDelete}
                    isDisabled={isBulkDeleteDisabled}
                />
            </ButtonsBlock>
            <SearchInputStyled
                type="text"
                placeholder={languageElements.ADMIN_CONTROLS_SEARCH_PLACEHOLDER}
                aria-label={languageElements.ADMIN_CONTROLS_SEARCH_EXPLANATION}
                role="searchbox"
                value={searchValue}
                onChange={onSetSearchValue}
            />
        </Container>
    );
};

const ButtonsBlock = styled.div`
    display: flex;
    align-items: center;
    padding-left: ${sizes.small}px;
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const Divider = styled.div`
    width: 1px;
    height: ${sizes.large}px;
    background: ${colors.neutralLight20};
    margin: 0 ${sizes.small}px;
`;

const CheckboxStyled = styled(Checkbox)`
    font-size: 14px;
`;

const SearchInputStyled = styled(SearchInput)`
    height: 30px;
    width: 275px;
    outline: none;
    line-height: 18px;
    font-weight: 500;
`;

export default AdminControlsActionsBar;
