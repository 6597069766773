import { AdminControlsData } from '../../common/interfaces';

export interface AdminControlsRowData extends AdminControlsData {
    index: number;
}

export enum HttpHeaders {
    PRAGMA = "Pragma",
    CONTENT_TYPE = "Content-Type",
    X_SMAR_TRACE_ID = "x-smar-trace-id",
}