import styled from '@emotion/styled';
import { sizes, Tab, TabList, TabPanel, Tabs } from '@smartsheet/lodestar-core';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncStatus } from '../../../common/enums';
import { AutomationIds } from '../../../common/enums/AutomationElements.enum';
import { DetailsPanelTabType } from '../../../common/enums/DetailsPanelTabType.enum';
import { IPaginatedResult, SmartsheetUser } from '../../../common/interfaces';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';
import { viewDataSelector } from '../Selectors';
import { Actions as DetailsPanelActions } from './Actions';
import AttachmentComponent from './Attachment/Attachment';
import Conversation from './Conversation/Conversation';
import { default as DetailsData } from './DetailsData/DetailsData';
import { getInitialTab } from './DetailsUtils';
import { detailsPanelShowModalSelector, isNewSubmissionSelector, selectDetailsIsDirty, selectStatusForCurrentRow } from './Selectors';
import { useInitializeComponent } from './useInitializeComponent';

export interface DetailsProps {
    detailsPanelDescription: string | undefined;
    initialTab?: DetailsPanelTabType;
    onCloseDetailsPanel: () => void;
    selectedRowId?: string;
    smartsheetUsers: IPaginatedResult<SmartsheetUser>;
    viewId: string;
    width: number;
}

export const Details = React.memo(({ viewId, initialTab, selectedRowId, width, smartsheetUsers, detailsPanelDescription }: DetailsProps) => {
    const dispatch = useDispatch();
    const languageElements = useLanguageElements();

    const isDetailsPanelDirty = useSelector(selectDetailsIsDirty);
    const isNewSubmission = useSelector(isNewSubmissionSelector);
    const detailsPanelShowModal = useSelector(detailsPanelShowModalSelector);
    const detailsDataSaveStatus = useSelector(selectStatusForCurrentRow);
    const viewData = useSelector(viewDataSelector);

    const [activeTab, setActiveTab] = useState(getInitialTab(isNewSubmission, initialTab));
    const [isCommentsLoading, setIsCommentsLoading] = useState(true);
    const [attachmentTotal, setAttachmentTotal] = useState(0);
    const [commentsTotal, setCommentsTotal] = useState(0);

    const initializeComponent = useCallback(() => {
        // trigger this at least once per mount
        setActiveTab(getInitialTab(isNewSubmission, initialTab));
    }, [initialTab, isNewSubmission]);

    useInitializeComponent(selectedRowId, isNewSubmission, initializeComponent);

    const handleUpdateCommentsTotal = (total: number) => {
        setIsCommentsLoading(false);
        setCommentsTotal(total);
    };

    const handleTabClick = (clickedTab: DetailsPanelTabType) => {
        // Don't do anything if the user clicked on the current tab.
        if (activeTab === clickedTab) {
            return;
        }

        if (activeTab === DetailsPanelTabType.DATA && detailsDataSaveStatus === AsyncStatus.NOT_STARTED) {
            return;
        }

        // If the user is moving away from the details tab, and they have unsaved changes, prompt them to first save.
        // Or if the user is moving away from the comments tab, and they have unsaved changes, ask them to first save.
        // The Attachments panel doesn't get dirty. Uploading attachments is a single step.
        const detailsDataNeedsSaving = activeTab === DetailsPanelTabType.DATA && isDetailsPanelDirty;
        const commentsNeedSaving = activeTab === DetailsPanelTabType.COMMENTS && isDetailsPanelDirty;
        if (detailsDataNeedsSaving || commentsNeedSaving) {
            dispatch(DetailsPanelActions.showModalDetailsPanel());
            return;
        }

        setActiveTab(clickedTab);
    };

    if (viewData.status !== AsyncStatus.PARTIAL && viewData.status !== AsyncStatus.DONE) {
        return null;
    }

    const { displayComments, addComments: canAddComments, displayAttachments, addAttachments: canAddAttachments } = viewData.data;

    return (
        <DetailsWrapper data-client-id={AutomationIds.DETAILS_WRAP}>
            <TitleContainer>
                <TitleStyled>{languageElements.DETAIL_PANEL_DETAILS}</TitleStyled>
                {detailsPanelDescription && <DescriptionStyled>{detailsPanelDescription}</DescriptionStyled>}
            </TitleContainer>
            <Tabs selectedId={activeTab} onChange={handleTabClick} onlyRenderActiveTabPanelChildren={false}>
                <TabListStyled aria-label={languageElements.DETAIL_PANEL_LABEL}>
                    <Tab id={DetailsPanelTabType.DATA} data-client-id={AutomationIds.DETAILS_TAB_DATA} data-testid={AutomationIds.DETAILS_TAB_DATA}>
                        {languageElements.DETAIL_PANEL_DATA}
                    </Tab>
                    {!isNewSubmission && displayAttachments && (
                        <Tab
                            id={DetailsPanelTabType.ATTACHMENTS}
                            data-client-id={AutomationIds.DETAILS_TAB_ATTACHMENTS}
                            data-testid={AutomationIds.DETAILS_TAB_ATTACHMENTS}
                        >
                            {languageElements.DETAIL_PANEL_ATTACHMENTS} ({attachmentTotal})
                        </Tab>
                    )}
                    {!isNewSubmission && displayComments && (
                        <Tab
                            id={DetailsPanelTabType.COMMENTS}
                            data-client-id={AutomationIds.DETAILS_TAB_COMMENTS}
                            data-testid={AutomationIds.DETAILS_TAB_COMMENTS}
                        >
                            {isCommentsLoading ? (
                                <>{languageElements.DETAIL_PANEL_COMMENTS} (0)</>
                            ) : (
                                `${languageElements.DETAIL_PANEL_COMMENTS} (${commentsTotal})`
                            )}
                        </Tab>
                    )}
                </TabListStyled>
                <TabPanelStyled>
                    <DetailsData
                        smartsheetUsers={smartsheetUsers}
                        viewId={viewId}
                        rowId={selectedRowId}
                        showModal={detailsPanelShowModal}
                        activeTab={activeTab === DetailsPanelTabType.DATA}
                        isNewSubmission={isNewSubmission}
                    />
                </TabPanelStyled>
                {!isNewSubmission && displayAttachments && (
                    <TabPanelStyled>
                        <AttachmentComponent
                            addAttachments={canAddAttachments ?? false}
                            viewId={viewId}
                            rowId={parseInt(selectedRowId!, 10)}
                            width={width}
                            onSetAttachmentTotal={setAttachmentTotal}
                            activeTab={activeTab === DetailsPanelTabType.ATTACHMENTS}
                        />
                    </TabPanelStyled>
                )}
                {!isNewSubmission && displayComments && (
                    <TabPanelStyled>
                        <Conversation
                            viewId={viewId}
                            rowId={selectedRowId!}
                            width={width}
                            updateCommentsTotal={handleUpdateCommentsTotal}
                            updateLoading={setIsCommentsLoading}
                            addComments={canAddComments}
                            showModal={detailsPanelShowModal}
                            activeTab={activeTab === DetailsPanelTabType.COMMENTS}
                        />
                    </TabPanelStyled>
                )}
            </Tabs>
        </DetailsWrapper>
    );
});

Details.displayName = 'Details';

const DetailsWrapper = styled.div`
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
`;

const TitleContainer = styled.div`
    padding: 15px;
    box-sizing: border-box;
`;

const TitleStyled = styled.h3`
    font-size: 18px;
    font-weight: 800;
    margin: 0 0 ${sizes.xSmall}px 0;
    height: 24px;
`;

const DescriptionStyled = styled.p`
    margin: 0;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
`;

const TabListStyled = styled(TabList)`
    padding: 0 ${sizes.medium}px;
`;

const TabPanelStyled = styled(TabPanel)`
    display: flex;
    overflow: hidden;
    flex: 1;
    width: 100%;
    height: 100%;
`;
