import { AdminControlsData, Contact, HomeView, SmartsheetUser } from '../../common/interfaces';

export class AdminControlsMapper {
    public static fromHomeView(view: HomeView): AdminControlsData {
        const name = (view.ownerDetails as SmartsheetUser)?.name ?? undefined;
        const profileImage = (view.ownerDetails as SmartsheetUser)?.profileImage ?? undefined;

        return {
            id: view.id,
            name: view.name || '',
            description: view.description,
            viewSourceType: view.viewSourceType,
            currentOwner: {
                email: view.ownerDetails?.email ?? '',
                name,
                profileImage,
            },
            shares: view.sharedWithGroups ? view.sharedWithGroups.length > 1 : false,
        };
    }

    public static fromAdminControlsDataToContact(data: AdminControlsData): Contact {
        return {
            email: data.currentOwner.email,
            name: data.currentOwner.name,
        };
    }
}
